// Variable overrides

$primary:                             #226CAF;
$info:                                #eb3c3c;
$dark:                                #333333;

$gray-300:                            #cfcfcf; // lighten($gray-base, 70%);
$gray-400:                            #bcacac; // lighten($gray-base, 60%);
$gray-500:                            #a68f8f; // lighten($gray-base, 50%);
$gray-600:                            #7c7575; // lighten($gray-base, 40%);
$gray-700:                            #735c5c; // lighten($gray-base, 30%);
$gray-800:                            #3a2f2f;
$gray-900:                            #2e2626; //DARK components

// Costum Colors

$body-bg:                             #e4e5e6;
$gray-body:                           #e4e5e6;
$table-dark-bg:                       #444444;

// Custom Styles

$navbar-height:                       70px;
$navbar-brand-width:                  250px;
$navbar-brand-minimized-width:        74px;

$sidebar-width:                       250px;
$sidebar-minimized-width:             74px;
$sidebar-bg:                          #26333B;
$sidebar-color:                       rgba(255,255,255,.8);
$sidebar-nav-color:                   rgba(255,255,255,.8);
$sidebar-nav-dropdown-color:          rgba(255,255,255,.8);
$sidebar-nav-dropdown-bg:             rgba(0,0,0,.1);
$sidebar-nav-dropdown-indicator-color:rgba(255,255,255,.4);
$sidebar-nav-title-padding-y:         1rem;
$sidebar-nav-title-padding-x:         1.5rem;
$sidebar-nav-title-color:             rgba(255,255,255,.6);
$sidebar-nav-link-padding-y:          1rem;
$sidebar-nav-link-padding-x:          1.5rem; 
$sidebar-nav-link-hover-bg:           #324049;
$sidebar-nav-link-hover-icon-color:   #ffffff;
$sidebar-nav-link-color:              rgba(255,255,255,.8);
$sidebar-nav-link-icon-color:         rgba(255,255,255,.6);
$sidebar-nav-link-active-bg:          rgba(0,0,0,.2);
$sidebar-nav-link-active-icon-color:  #ffffff;
$sidebar-nav-link-active-borders:     0;
$sidebar-minimizer-indicator-color:   #26333B;
