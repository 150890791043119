#wrapper {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
}

.wrapper {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
}

.over-layer {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
}



@include media-breakpoint-up(lg) {

    //
    // Header
    //
    .header-fixed {
        .app-header {
            position: fixed;
            z-index: $zindex-sticky;
            width: inherit;
        }

        .app-body {
            margin-top: 0;
        }
    }

    //
    // Sidebar
    //
    // .sidebar-fixed {
    //     .sidebar {
    //         // margin-top: $navbar-height;
    //         margin-top: 0;
    //     }
    // }
}

@include media-breakpoint-up(md) {

    //
    // Header
    //
    .header-fixed {
        .app-header {
            position: fixed;
            z-index: $zindex-sticky;
            width: inherit;
        }

        .app-body {
            margin-top: 0;
        }
    }
}

@include media-breakpoint-up(xs) {

    //
    // Header
    //
    .header-fixed {
        .app-header {
            position: fixed;
            z-index: $zindex-sticky;
            width: inherit;
        }

        .app-body {
            margin-top: 0;
        }
    }
}

.user-nav {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.5rem 0.5rem;
    margin-top: 0.6rem;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    opacity: 0.7;
    z-index: 1030;
}

@include media-breakpoint-up(lg) {
    .user-nav {
        margin-right: -1.5rem;
    }

    .user-nav:hover,
    .user-nav:active {
        margin-right: 0;
    }
}

.user-nav:hover,
.user-nav:active {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
}

.user-nav .nav-link {
    padding: 0;
}

.app-header .nav-item .nav-link>.img-avatar,
.app-header .nav-item .avatar.nav-link>img {
    height: 30px;
    margin: 0 5px;
}

.sidebar-fixed .app-header+.app-body .sidebar {
    height: calc(100vh);
}

.full-view {
    width: 100%;
    height: 100%;
    position: absolute;
}

.mini-view {
    width: 25%;
    height: 25%;
    z-index: 9;
    position: absolute;
    // top: 5px;
    right: 5px;
    bottom: 5px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.width-full-element {
    width: -webkit-fill-available;
    width: -moz-available;
}

.container1 {
    background-color: #070606;
    border-radius: 10px;
    margin: 5px;
    position: relative;
}

.video-box-1 {
    width: 300px;
}

.video-box-2 {
    width: 600px;
}

.video-box-240p {
    width: 426px;
    height: 240px;
}

.video-box-360p {
    width: 640px;
    height: 360px;
}

.video-box-480p {
    width: 854px;
    height: 480px;
}

.video-box-720p {
    width: 1280px;
    height: 720px;
}

.showme {
    display: none;
    // display: -webkit-flex;
}

.showhim:hover .showme {
    display: -webkit-flex;
    // display: block;
    justify-content: flex-end;
}

.box-right-bottom {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

